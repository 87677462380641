@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, .App {
  height: 100%;
  width: 100%;
  margin: 0;
  left: 0;
  top: 0;
}

.card--general {
  border-radius: 8px;
  background: white;
  border-collapse: collapse;
  box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
  display: inline-block;
  padding: 32px;
}

.span--label {
  display: block;
  margin-bottom: 8px;
}

.spacer--24 {
  display: block;
  margin-bottom: 24px;
}

h1, h2 {
  font-weight: 600;
}

a {
  text-decoration: none;
}

a:visited, a:link, a:active {
  color: inherit;
}

* {
  box-sizing: border-box;
}

body {
  background: #F4F5F8;
  color: #434A5D;
  font-family: 'Work Sans', sans-serif;
}

.color--secondary {
  color: #A6AAB4;
}

.pagination {
  margin: 24px 0px;
}
.login-form__holder {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    padding: 42px;
    padding-top: 16px;
    background: white;
    border-radius: 12px;
    box-shadow: 5px 5px 20px rgba(108, 120, 172, 0.12);
    width: 100%;
    max-width: 440px;
}

.login__h1 {
    font-size: 2.5em;
    line-height: 0.5em;
}

.login__h2 {
    line-height: 0.5em;
    font-weight: 500;
    margin-bottom: 48px;
    display: block;
}

.button.login__form__button {
    width: 100%;
    border-radius: 6px;
    background: #EC5E14;
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding: 12px;
    border: none;
    transition: all 0.15s;
    justify-content: center;
}

.login__form__input {
    width: 100%;
    font-size: 18px;
    padding: 12px;
}

.form__button:disabled, .form__button:disabled:hover {
    background: #A6AAB4;
    color: #434A5D;
    cursor: not-allowed;
}

.form__button:hover {
    background: #db540b;
    cursor: pointer;
}

.form__combo {
    margin-bottom: 24px;
}

.form__logo {
    position: absolute;
    bottom: 25px;
    left: 50%;
    margin-left: -90px;
    width: 180px;
    z-index: -1;
}
.frame__sidebar {
    width: 80px;
    height: 100%;
    background: white;
    position: fixed;
    top: 0px;
    border-right: 1px solid #e9eaee;
    display: flex;
    flex-direction: column;
}

.frame__logo img {
    max-width: 100%;
}

.frame__logo {
    height: 80px;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px;
}

.frame__header {
    height: 80px;
    width: calc(100% - 80px);
    position: absolute;
    top: 0px;
    left: 80px;
    background: white;
    border-bottom: 1px solid #e9eaee;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    align-items: center;
    padding: 12px;
}

.frame__header__username {
    font-weight: 500;
}

.frame__header__logout {
    margin-top: 4px;
    display: block;
}

.frame__header__logout:hover {
    color: #EC5E14;
    cursor: pointer;
}

.frame__sidebar__icons {
    text-align: center;
    margin-top: 24px;
}

.frame__sidebar__icon {
    height: 25px;
    width: 25px;
    margin: 12px auto;
    color: #A6AAB4;
}

.frame__sidebar__icon--secondary {
    height: 20px;
    width: 20px;
}

.frame__sidebar__icon__tooltip {
    position: absolute;
    right: -25px;
    opacity: 0;
    max-height: 0px;
    top: 50%;
    text-align: left;
    transform: translateY(-50%) translateX(100%);
    white-space: nowrap;
    background: #434A5D;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 5px 5px 10px rgba(67, 74, 93, 0.12);
}

.frame__sidebar__icon__holder:hover > .frame__sidebar__icon__tooltip {
    opacity: 1;
    max-height: 30px;
    right: -5px;
    transition: opacity 0.35s, right 0.35s;
    padding: 6px 8px;
}

.frame__sidebar__icon__holder {
    position: relative;
    display: block;
    text-decoration: none;
}

.frame__sidebar__icon__holder:hover > .frame__sidebar__icon, .frame__sidebar__icon--active {
    color: #EC5E14;
}

.frame__sidebar__icon__holder--active::before {
    position: absolute;
    left: 50%;
    height: 45px;
    top: 50%;
    width: 45px;
    margin-top: -22.5px;
    margin-left: -22.5px;
    background-color: #EC5E14;
    opacity: 0.1;
    border-radius: 8px;
    content: '';
    display: block;
}

.frame__sidebar__icon__holder--secondary--active::before {
    position: absolute;
    left: 10px;
    height: 5px;
    top: 50%;
    width: 5px;
    border-radius: 10px;
    margin-top: -5px;
    background-color: #EC5E14;
    content: '';
    display: block;
}

.frame {
    height: 100%;
}

.frame__children {
    display: block;
    padding: 120px 140px;
    padding-right: 40px;
}
.agents__trash, .agents__manage {
    transition: all 0.15s;
}

.agents__trash:hover {
    color: rgb(226, 36, 36);
    opacity: 1;
    cursor: pointer;
}

.agents__manage:hover {
    cursor: pointer;
    color: #EC5E14;
}
.modal {
    position: fixed;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    border-radius: 8px;
    background: white;
    width: 100%;
    max-width: 640px;
    overflow: hidden;
}

.modal__overlay {
    position: fixed;
    background: #434A5D;
    opacity: 0.3;
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
}

.modal__header {
    display: flex;
    background: #434A5D;
    padding: 20px 24px;
}

.modal__header h2 {
    color: white;
    margin: 0px;
    line-height: 1em;
    font-weight: 600;
}

.modal__close {
    margin-left: auto;
    color: white;
    height: 20px;
    width: 20px;
}

.modal__content {
    padding: 24px;
}

.modal__buttons {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    padding-top: 0px;
}
.button {
    border: 0px;
    border-radius: 6px;
    padding: 12px;
    font-size: 15px;
    margin-right: 12px; 
    transition: all 0.2s;
    display: inline-flex;
    align-items: center;
}

.button:hover {
    cursor: pointer;
}

.button:disabled, .button:disabled:hover {
    background: #e9eaee;
    color: #d3d4da;
    cursor: not-allowed;
    box-shadow: none;
}

.button--primary {
    background: #EC5E14;
    color: white;
    box-shadow: 0px 10px 15px -10px #ec6014;
}

.button--primary:hover {
    background: #db540b;
    box-shadow: 0px 10px 10px -10px #ec6014;
}

.button--secondary {
    background: #e9eaee;
    color: #434A5D;
}

.button--secondary:hover {
    background: #d3d4da;
}

.button--bold {
    font-weight: bold;
}
.table {
    border-radius: 8px;
    background: white;
    border-collapse: collapse;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
}

.table tr {
    transition: background-color 0.15s;
}

.table tr:first-child:hover {
    background-color: transparent;
}

.table tr:last-child td {
    border-bottom: none;
}

.table td, .table th {
    padding: 16px 24px;
    border-bottom: 2px solid #e9eaee;
}

.table tr:hover {
    background-color: rgba(236, 96, 20, 0.04);
}

.table tr .util {
    opacity: 0;
    transition: opacity 0.2s;
}

.table tr:hover .util {
    opacity: 1;
}
.page__header {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    max-width: 1080px;
}

.page__header__actions {
    margin-left: auto;
}

.page__haeder__loader {
    margin-left: 12px;
    line-height: 1em;
}
.breadcrumbs__back {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    justify-content: flex-start;
}

.breadcrumbs__back__icon {
    margin-right: 2px;
    margin-left: 4px;
    transition: margin 0.15s;
}

.breadcrumbs__back:hover .breadcrumbs__back__icon {
    margin-left: 0px;
    margin-right: 6px;
}
.form {
    padding: 32px;
    background: white;
    border-radius: 8px;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
    display: inline-block;
}

.form--unset {
    display: block;
}

.form__group {
    margin-bottom: 20px;
}

.form__error {
    padding: 12px;
    margin-bottom: 32px;
    background: rgba(226, 36, 36, 0.05);
    border: 2px solid rgb(226, 36, 36);
    color: rgb(226, 36, 36);
    border-radius: 6px;
}

.form__label {
    display: block;
    margin-bottom: 8px;
    text-transform: capitalize;
}

.form__input {
    border-radius: 6px;
    border: 2px solid #e9eaee;
    padding: 8px;
    font-size: 1em;
    transition: all 0.15s;
}

.form__input:focus {
    border: 2px solid #EC5E14;
    outline: none;
}
.license__status {
    height: 8px;
    width: 8px;
    border-radius: 10px;
    display: block;
    margin: auto;
}

.license__status--active {
    background: rgb(3, 209, 147);
}

.license__status--hold {
    background: rgb(241, 167, 8);
}

.license__status--expired {
    background: rgb(241, 8, 8);
}

.license__search {
    margin-bottom: 24px;
    display: flex;
}

.license__search__targets .form__group {
    margin: 0px;
    margin-right: 12px;
}

.license__search__targets {
    margin-left: 24px;
}

.license__search__filter {
    display: block;
    margin-top: 8px;
}
.dash__card {
    background: white;
    border-radius: 16px;
    padding: 24px;
    min-width: 280px;
    display: inline-block;
    margin-right: 24px;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
}

.dash__key {
    position: relative;
}

.dash__key::after {
    content: '';
    position: absolute;
    background: #EC5E14;
    height: 5px;
    width: 5px;
    border-radius: 100%;
    right: -15px;
    top: 50%;
    margin-top: -2.5px;
    animation: pulse 1s infinite alternate-reverse ease-in;
}

@-webkit-keyframes pulse{
    from{
        opacity: 0.2;
        transform: scale(0.9);
    }
    to{
        opacity: 1;
        transform: scale(1.5);
    }
}

@keyframes pulse{
    from{
        opacity: 0.2;
        transform: scale(0.9);
    }
    to{
        opacity: 1;
        transform: scale(1.5);
    }
}
.license__status {
    height: 8px;
    width: 8px;
    border-radius: 10px;
    display: block;
    margin: auto;
}

.license__status--active {
    background: rgb(3, 209, 147);
}

.license__status--hold {
    background: rgb(241, 167, 8);
}

.license__status--expired {
    background: rgb(241, 8, 8);
}

.license__search {
    margin-bottom: 24px;
    display: flex;
}

.license__search__targets .form__group {
    margin: 0px;
    margin-right: 12px;
}

.license__search__targets {
    margin-left: 24px;
}

.license__search__filter {
    display: block;
    margin-top: 8px;
}
