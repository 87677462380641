.breadcrumbs__back {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    justify-content: flex-start;
}

.breadcrumbs__back__icon {
    margin-right: 2px;
    margin-left: 4px;
    transition: margin 0.15s;
}

.breadcrumbs__back:hover .breadcrumbs__back__icon {
    margin-left: 0px;
    margin-right: 6px;
}