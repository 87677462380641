.dash__card {
    background: white;
    border-radius: 16px;
    padding: 24px;
    min-width: 280px;
    display: inline-block;
    margin-right: 24px;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
}

.dash__key {
    position: relative;
}

.dash__key::after {
    content: '';
    position: absolute;
    background: #EC5E14;
    height: 5px;
    width: 5px;
    border-radius: 100%;
    right: -15px;
    top: 50%;
    margin-top: -2.5px;
    animation: pulse 1s infinite alternate-reverse ease-in;
}

@keyframes pulse{
    from{
        opacity: 0.2;
        transform: scale(0.9);
    }
    to{
        opacity: 1;
        transform: scale(1.5);
    }
}