@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');

html, body, #root, .App {
  height: 100%;
  width: 100%;
  margin: 0;
  left: 0;
  top: 0;
}

.card--general {
  border-radius: 8px;
  background: white;
  border-collapse: collapse;
  box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
  display: inline-block;
  padding: 32px;
}

.span--label {
  display: block;
  margin-bottom: 8px;
}

.spacer--24 {
  display: block;
  margin-bottom: 24px;
}

h1, h2 {
  font-weight: 600;
}

a {
  text-decoration: none;
}

a:visited, a:link, a:active {
  color: inherit;
}

* {
  box-sizing: border-box;
}

body {
  background: #F4F5F8;
  color: #434A5D;
  font-family: 'Work Sans', sans-serif;
}

.color--secondary {
  color: #A6AAB4;
}

.pagination {
  margin: 24px 0px;
}