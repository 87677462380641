.license__status {
    height: 8px;
    width: 8px;
    border-radius: 10px;
    display: block;
    margin: auto;
}

.license__status--active {
    background: rgb(3, 209, 147);
}

.license__status--hold {
    background: rgb(241, 167, 8);
}

.license__status--expired {
    background: rgb(241, 8, 8);
}

.license__search {
    margin-bottom: 24px;
    display: flex;
}

.license__search__targets .form__group {
    margin: 0px;
    margin-right: 12px;
}

.license__search__targets {
    margin-left: 24px;
}

.license__search__filter {
    display: block;
    margin-top: 8px;
}