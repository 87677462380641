.agents__trash, .agents__manage {
    transition: all 0.15s;
}

.agents__trash:hover {
    color: rgb(226, 36, 36);
    opacity: 1;
    cursor: pointer;
}

.agents__manage:hover {
    cursor: pointer;
    color: #EC5E14;
}