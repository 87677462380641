.table {
    border-radius: 8px;
    background: white;
    border-collapse: collapse;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
}

.table tr {
    transition: background-color 0.15s;
}

.table tr:first-child:hover {
    background-color: transparent;
}

.table tr:last-child td {
    border-bottom: none;
}

.table td, .table th {
    padding: 16px 24px;
    border-bottom: 2px solid #e9eaee;
}

.table tr:hover {
    background-color: rgba(236, 96, 20, 0.04);
}

.table tr .util {
    opacity: 0;
    transition: opacity 0.2s;
}

.table tr:hover .util {
    opacity: 1;
}