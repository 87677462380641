.modal {
    position: fixed;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    border-radius: 8px;
    background: white;
    width: 100%;
    max-width: 640px;
    overflow: hidden;
}

.modal__overlay {
    position: fixed;
    background: #434A5D;
    opacity: 0.3;
    backdrop-filter: blur(30px);
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
}

.modal__header {
    display: flex;
    background: #434A5D;
    padding: 20px 24px;
}

.modal__header h2 {
    color: white;
    margin: 0px;
    line-height: 1em;
    font-weight: 600;
}

.modal__close {
    margin-left: auto;
    color: white;
    height: 20px;
    width: 20px;
}

.modal__content {
    padding: 24px;
}

.modal__buttons {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    padding-top: 0px;
}