.button {
    border: 0px;
    border-radius: 6px;
    padding: 12px;
    font-size: 15px;
    margin-right: 12px; 
    transition: all 0.2s;
    display: inline-flex;
    align-items: center;
}

.button:hover {
    cursor: pointer;
}

.button:disabled, .button:disabled:hover {
    background: #e9eaee;
    color: #d3d4da;
    cursor: not-allowed;
    box-shadow: none;
}

.button--primary {
    background: #EC5E14;
    color: white;
    box-shadow: 0px 10px 15px -10px #ec6014;
}

.button--primary:hover {
    background: #db540b;
    box-shadow: 0px 10px 10px -10px #ec6014;
}

.button--secondary {
    background: #e9eaee;
    color: #434A5D;
}

.button--secondary:hover {
    background: #d3d4da;
}

.button--bold {
    font-weight: bold;
}